import React, { useEffect, useState } from "react"
import Layout from "../../../components/layout"
import Intro from "../../../components/intro"

const SongDanceability = () => {
  const meta = {
    title: "Spotify Track Danceability",
    subject: "Math",
    description: `Spotify defines Danceability in these words: “Danceability describes how suitable a track is for dancing based on a combination of musical elements including tempo, rhythm stability, beat strength, and overall regularity. A value of 0.0 is least danceable and 1.0 is most danceable.”`,
    questions: [],
    source: "The Spotify API",
  }

  const [authToken, setAuthToken] = useState("")

  const spotifyAuthPost = async () => {
    const AUTH_URL = `https://accounts.spotify.com/api/token`
    const response = await fetch(AUTH_URL, {
      method: "POST",
      headers: {
        Authorization: `Basic NmQ4ZDgwOGMyZWRjNGU0ZjhjZGFjN2JiZDA1OTQ2NTU6ZmE5MzVhYTA5OWZiNGYzN2EyOGFmMDBmMDVjZjIzOGE=`,
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: "grant_type=client_credentials",
    })

    if (response.status === 200) {
      const data = await response.json()
      return data
    }
  }

  const setAccessToken = () =>
    spotifyAuthPost().then(res => setAuthToken(res.access_token))

  const [playlists, setPlaylists] = useState()

  const fetchPlaylists = async text => {
    const URL = `https://api.spotify.com/v1/search?q=${text}&type=playlist`
    const response = await fetch(URL, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })

    if (response.status === 200) {
      const data = await response.json()
      console.log(data.playlists.items)
      return data.playlists.items
    }
  }

  const [playlistTracks, setPlaylistTracks] = useState([])

  const fetchPlaylistTracks = async id => {
    const URL = `https://api.spotify.com/v1/playlists/${id}/tracks`
    const response = await fetch(URL, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })

    if (response.status === 200) {
      const data = await response.json()
      console.log(data.items)
      return data.items
    }
  }

  const [trackInfo, setTrackInfo] = useState([])

  const fetchTrackInfo = async ids => {
    const trackIds = ids.join(",")
    const URL = `https://api.spotify.com/v1/audio-features/?ids=${trackIds}`
    const response = await fetch(URL, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })

    if (response.status === 200) {
      const data = await response.json()
      return data.audio_features
    }
  }

  const [showValue, setShowValue] = useState([])
  const [difference, setDifference] = useState([])
  const [estimates, setEstimates] = useState([])

  const showPlaylistTrackList = id => {
    return fetchPlaylistTracks(id).then(res => {
      setPlaylistTracks(res)
      fetchTrackInfo(res.map(item => item.track.id)).then(res => {
        setTrackInfo(res)
        setShowValue(res.map(item => false))
        setDifference(res.map(item => 0))
        setEstimates(res.map(item => 0))
      })
    })
  }

  const [text, setText] = useState("")

  useEffect(() => {
    if (authToken.length == 0) {
      setAccessToken().then(() => {
        setTimeout(() => {
          fetchPlaylists(text).then(res => {
            setPlaylists(res)
          })
        }, 20)
      })
    } else {
      fetchPlaylists(text)
        .then(res => {
          setPlaylists(res)
        })
        .then(() => {
          setPlaylistTracks([])
          setDifference([])
          setEstimates([])
          setShowValue([])
        })
    }
  }, [text])

  const [songIndex, setSongIndex] = useState()
  const [selectedPlaylist, setSelectedPlaylist] = useState("")

  return (
    <Layout>
      <Intro data={meta} />
      <label className="label">Search Playlists</label>
      <input
        className="input"
        style={{ maxWidth: 400 }}
        type="text"
        placeholder="Text input"
        onChange={e => setText(e.target.value)}
      />
      <br />
      <br />
      <br />
      {playlistTracks.length > 0 && (
        <p>
          <b>Selected Playlist:</b> {selectedPlaylist}
        </p>
      )}
      <br />
      {playlists &&
        playlists.map(item => (
          <button
            className="button"
            style={{
              padding: "0 20px",
              marginRight: 10,
              marginBottom: 10,
            }}
            onClick={() => {
              showPlaylistTrackList(item.id)
              setPlaylists([])
              setSelectedPlaylist(
                `${item.name} - by ${item.owner.display_name}`
              )
            }}
          >
            {item.name} - by {item.owner.display_name}
          </button>
        ))}

      {playlistTracks.length > 0 && (
        <table className="table">
          <thead>
            <tr>
              <th>Song</th>
              <th>Artist</th>
              <th>Estimate</th>
              <th>Danceability</th>
              <th>Difference</th>
            </tr>
          </thead>
          <tbody>
            {playlistTracks.map((item, i) => (
              <tr>
                <th>{item.track && item.track.name}</th>
                <td>{item.track && item.track.artists[0].name}</td>
                <td>
                  <input
                    className="input"
                    style={{ maxWidth: 100 }}
                    type="number"
                    onChange={e =>
                      setEstimates(
                        estimates.map((item, index) => {
                          if (i === index) {
                            return e.target.value
                          }
                          return item
                        })
                      )
                    }
                  />
                </td>
                <td>
                  {!showValue[i] ? (
                    <button
                      className="button"
                      onClick={() => {
                        setShowValue(
                          showValue.map((item, index) => {
                            if (i === index) {
                              return true
                            }
                            return item
                          })
                        )
                        setDifference(
                          difference.map((item, index) => {
                            if (i === index) {
                              return (
                                estimates[index] -
                                Number(
                                  JSON.stringify(trackInfo[i].danceability)
                                )
                              )
                            }
                            return item
                          })
                        )
                      }}
                    >
                      Reveal Value
                    </button>
                  ) : (
                    JSON.stringify(trackInfo[i].danceability)
                  )}
                </td>
                <td>{difference.length > 0 && difference[i].toFixed(3)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </Layout>
  )
}

export default SongDanceability
